// @flow

import React from 'react'
import MuiButton from '@material-ui/core/Button'
import Link from './Link'

type Props = {
  to?: string,
  children?: any,
}
const Button = (props: Props) => {
  const { to, children, ...rest } = props
  return to ? (
    <Link to={to} noUnderline>
      <MuiButton {...rest}>{children}</MuiButton>
    </Link>
  ) : (
    <MuiButton {...rest}>{children}</MuiButton>
  )
}

export default Button
