// @flow

import React from 'react'
import { render } from 'react-dom'
import { injectGlobal } from 'styled-components'

import { Provider as ReduxProvider } from 'react-redux'
import { ApolloProvider } from 'react-apollo'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import { CookiesProvider } from 'react-cookie'

import { DragDropContextProvider } from 'react-dnd'
import HTML5DnD from 'react-dnd-html5-backend'

import { rebassTheme, muiTheme } from './config'
import { client } from './apollo'
import createStore from './store'
import { createBrowserHistory } from 'history'

import createRouter from './router'
import { unregister } from './utils/registerServiceWorker'

import 'react-table/react-table.css'

// eslint-disable-next-line
injectGlobal`
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: ${rebassTheme.background};
  overflow: hidden;
}

.ql-toolbar.ql-snow {
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
}

.ql-container.ql-snow {
  border-bottom: hidden;
  border-left: hidden;
  border-right: hidden;
  font-size: 1em;
  font-family: 'Open Sans', sans-serif;
}
`

const history: History = createBrowserHistory()

createStore(history).then((store) => {
  const Router = createRouter(history)
  render(
    <ReduxProvider store={store}>
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CookiesProvider>
              <DragDropContextProvider backend={HTML5DnD}>
                {Router}
              </DragDropContextProvider>
            </CookiesProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </ApolloProvider>
    </ReduxProvider>,
    document.getElementById('root')
  )
})

unregister()
