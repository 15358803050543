// @flow

import { REHYDRATE } from 'redux-persist/constants'
import type { Reducer } from 'redux'
import type { State, Action } from '../../types/login'

const defaultState: State = {
  error: null,
  loading: false,
  employee: null,
  name: '',
  token: null,
}

const reducer: Reducer<State, Action> = (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'LOGIN':
      return {
        ...state,
        loading: true,
      }
    case 'LOGIN_SUCCESS':
      const { token, employee } = payload
      return {
        ...state,
        error: null,
        loading: false,
        employee,
        token,
      }
    case 'LOGIN_FAILURE':
      return {
        ...state,
        error: payload.message || payload.toString() || 'Unknown error',
        loading: false,
        employee: null,
        token: null,
      }
    case 'LOGOUT':
      return defaultState
    case REHYDRATE:
      // Remove error and loading upon redux state rehydration
      return {
        ...state,
        ...payload.incoming,
        error: null,
        loading: false,
      }
    default:
      return state
  }
}

export default reducer
