import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { call as callApi } from '../../utils/api'

const LoginMutation = `
  mutation Login($email: String!, $password: String!) {
    employeeLogin(email: $email, password: $password) {
      success
      token
      employee {
        id
        firstName
        lastName
        image
      }
    }
  }
`

export function* login(action) {
  try {
    const { email, password } = action.payload

    // Check parameters
    if (!email) {
      throw new Error('Email is required for login')
    }
    if (!password) {
      throw new Error('Password is required for login')
    }

    const res = yield call(callApi, LoginMutation, { email, password })
    const { success, token, employee } = res.data.employeeLogin

    if (success) {
      // Set the auth
      yield put({
        type: 'LOGIN_SUCCESS',
        payload: { token, employee },
        error: false,
      })

      // Redirect elsewhere
      const previous = yield select((state) => state.router.location.state)
      const { from } = previous || { from: { pathname: '/' } }
      yield put(push(from))
    } else {
      // Remove auth
      yield put({
        type: 'LOGIN_FAILURE',
        payload: new Error('Invalid username or password'),
        error: true,
      })
    }
  } catch (err) {
    console.error(err)
    // Remove auth
    yield put({
      type: 'LOGIN_FAILURE',
      payload: err,
      error: true,
    })
  }
}

const refreshTokenMutation = `
  mutation RefreshToken($token: String!) {
    employeeVerify(token: $token) {
      success
      token
      employee {
        id
        firstName
        lastName
        image
      }
    }
  }
`
  .trim()
  .replace(/\s+/g, ' ')

function* refreshToken(action) {
  let currentToken = yield select((state) => state.auth.token)

  if (!currentToken) {
    yield put({
      type: 'LOGIN_FAILURE',
      payload: {
        message: 'Login token does not exist.',
      },
    })
    return
  }

  const res = yield call(callApi, refreshTokenMutation, { token: currentToken })
  const { success, token, employee } = res.data.employeeVerify

  if (success) {
    yield put({
      type: 'LOGIN_SUCCESS',
      payload: { token, employee },
    })
  } else {
    yield put({
      type: 'LOGIN_FAILURE',
      payload: {
        message: 'Login token invalid, likely expired.',
      },
    })
  }
}

export default function* main() {
  yield all([
    takeLatest('LOGIN', login),
    takeLatest('REFRESH_TOKEN', refreshToken),
  ])
}
