import Loadable from 'react-loadable'
import { Loading } from '../components'

export const App = Loadable({
  loader: () => import(/* webpackChunkName: "App" */ './App'),
  loading: Loading,
})

export const Banner = Loadable({
  loader: () => import(/* webpackChunkName: "Banner" */ './Banner'),
  loading: Loading,
})

export const Category = Loadable({
  loader: () => import(/* webpackChunkName: "Category" */ './Category'),
  loading: Loading,
})

export const Classroom = Loadable({
  loader: () => import(/* webpackChunkName: "Classroom" */ './Classroom'),
  loading: Loading,
})

export const Course = Loadable({
  loader: () => import(/* webpackChunkName: "Course" */ './Course'),
  loading: Loading,
})

export const Curriculum = Loadable({
  loader: () => import(/* webpackChunkName: "Curriculum" */ './Curriculum'),
  loading: Loading,
})

export const Employee = Loadable({
  loader: () => import(/* webpackChunkName: "Employee" */ './Employee'),
  loading: Loading,
})

export const FileUploader = Loadable({
  loader: () => import(/* webpackChunkName: "FileUploader" */ './FileUploader'),
  loading: Loading,
})

export const HomePage = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './Home'),
  loading: Loading,
})

export const LessonPlanFile = Loadable({
  loader: () =>
    import(/* webpackChunkName: "LessonPlanFile" */ './LessonPlanFile'),
  loading: Loading,
})

export const LessonPlanFileUploader = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FileUploader" */ './LessonPlanFileUploader'),
  loading: Loading,
})

export const ProblemSetGraphViewer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ProblemSetGraphViewer" */ './ProblemSetGraphViewer'
    ),
  loading: Loading,
})

export const CenterLocation = Loadable({
  loader: () => import(/* webpackChunkName: "Location" */ './CenterLocation'),
  loading: Loading,
})

export const LoginPage = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ './Login'),
  loading: Loading,
})

export const NotFound = Loadable({
  loader: () => import(/* webpackChunkName: "NotFound" */ './NotFound'),
  loading: Loading,
})

export const Student = Loadable({
  loader: () => import(/* webpackChunkName: "Student" */ './Student'),
  loading: Loading,
})

export const Script = Loadable({
  loader: () => import(/* webpackChunkName: "Script" */ './Script'),
  loading: Loading,
})

export const Reports = Loadable({
  loader: () => import(/* webpackChunkName: "Reports" */ './Reports'),
  loading: Loading,
})

export { default as rootSaga } from './sagas'
