// @flow

/*
 * Intellignent prop passing.
 * If `passProps` is a function, there is special handling that calls it with the original props.
 * In that mode, `passProps` is responsible for also returning any original props that should be passed along.
 *
 * Patterns: (Example: Outer -> Middle -> Inner -> htmlComponent)
 * Inner component implementation: spread props to html component
 * `<htmlComponent {...propPass({ prop1: 'value1' }, propPass)}>`
 * Middle component implementation: allow both self and outer component to modify props
 * `<Inner passedProps={oProps => propPass({ ...oProps, ...modification }, passedProps)}>`
 * Outer component implementation: simple merge vs advanced modification
 * `<Middle passedProps={{ merge1: 'value1' }}>`
 * `<Middle passedProps={oProps => ({ ...oProps, someFunction: input => { oProps.someFunction(input); this.someAction(input) } })}>`
 */
export const propPass = (originalProps: any, passProps: any) => {
  if (typeof passProps === 'function') {
    return passProps(originalProps)
  } else {
    return {
      ...originalProps,
      ...passProps,
    }
  }
}
