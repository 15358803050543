// @flow

import { applyMiddleware, combineReducers, createStore } from 'redux'
import { getStoredState, persistStore } from 'redux-persist'
import { routerMiddleware, routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import localForage from 'localforage'

import * as reducers from './containers/reducers'
import rootSaga from './containers/sagas'

const persistConfig = {
  whitelist: ['auth'],
  storage: localForage,
}

const createCustomStore = async (history: History) => {
  const allReducers = combineReducers({
    ...reducers,
    form: formReducer,
    router: routerReducer,
  })

  const sagaMiddleware = createSagaMiddleware()

  const middleware = composeWithDevTools(
    applyMiddleware(routerMiddleware(history), sagaMiddleware)
  )

  // Load the data stored on localStorage (or WebSQL)
  const initialState: any = await getStoredState(persistConfig)

  const store = createStore(allReducers, initialState, middleware)

  // Save a specified portion of the store to localStorage
  persistStore(store, persistConfig, () => {
    console.log('Redux rehydration complete')
  })

  // Run the sagas!
  sagaMiddleware.run(rootSaga)

  return store
}

export default createCustomStore
