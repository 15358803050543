// @flow

import * as React from 'react'
import katex from 'katex'
import { change } from 'redux-form'
import { Button } from './'

const latexRegex = /\\\((.*?)\\\)/g
//const latexToKatexRegex = /(\$\\|\\\[)(.*?)(\$|\\\])/g
const latexToKatexRegex = /(\$|\\\[)(.*?)(\$|\\\])/g

type Props = {
  Component?: React.ComponentType<any>,
  latex: string,
}

export const LatexToKatexButton = (props) => {
  const onClick = () => {
    props.meta.dispatch(
      change(
        props.actiontType,
        props.input.name,
        convertLatexToKatex(props.input.value)
      )
    )
  }

  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={onClick}
      style={{ marginLeft: 'auto' }}
    >
      Latex To Katex
    </Button>
  )
}

export const convertLatexToKatex = (latex) => {
  let result = ''
  try {
    const test = latex.split(latexToKatexRegex)
    console.log(test)
    let leftTag = false
    latex.split(latexToKatexRegex).forEach((content) => {
      if (content === '$' && !leftTag) {
        result += '\\('
        leftTag = true
      } else if (content === '$' && leftTag) {
        result += '\\)'
        leftTag = false
      } else if (content === '\\[') {
        result += '\\('
      } else if (content === '\\]') {
        result += '\\\\\\)'
      } else {
        result += content
      }
    })
    return result
  } catch (e) {
    return result
  }
}

export const LaTeX = (props: Props) => {
  const Component = props.Component
  try {
    return (
      <Component>
        {props.latex.split(latexRegex).map((content, index) => {
          if (index & 1) {
            return (
              <span
                key={content}
                dangerouslySetInnerHTML={{
                  __html: katex.renderToString(content, {
                    throwOnError: false,
                  }),
                }}
              />
            )
          } else {
            if (content.includes('\n\n')) {
              const c = content.split('\n\n')
              return (
                <React.Fragment>
                  {c.map((content, index) => {
                    return (
                      <span>
                        {content}
                        {index !== c.length - 1 ? <br /> : ''}
                      </span>
                    )
                  })}
                </React.Fragment>
              )
            }
            return content
          }
        })}
      </Component>
    )
  } catch (_) {
    return <React.Fragment>Invalid LaTeX Format</React.Fragment>
  }
}

LaTeX.defaultProps = {
  Component: 'span',
}

export default LaTeX
