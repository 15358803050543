// @flow

import React from 'react'
import Drawer from '@material-ui/core/Drawer'

import NavList from './NavList'

import type { User } from '../types/user'

type Props = {
  isOpen: boolean,
  close: (reason: string) => void,
  user: ?User,
}

const NavDrawer = (props: Props) => {
  return (
    <Drawer
      open={props.isOpen}
      onRequestClose={props.close}
      onClick={props.close}
    >
      <NavList />
    </Drawer>
  )
}
export default NavDrawer
