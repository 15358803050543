// Created on 2018.07.20 by Jack Sun
// Updated on 2018.08.24 by Jack Sun

// The loading component for `react-loadable`

import React from 'react'

import Text from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

const Loading = ({ pastDelay, error }) => {
  if (error) {
    return (
      <Text style={{ color: 'red' }} onClick={() => alert(error)}>
        Unexpected error.
      </Text>
    )
  } else if (pastDelay) {
    return <CircularProgress />
  } else {
    return null
  }
}

export default Loading
