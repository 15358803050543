// @flow

import styled from 'styled-components'

type Props = {
  color?: string,
  theme: Object,
}

export default styled.h1`
  color: ${(props: Props) => props.color || props.theme.header};
  font-size: 24px;
  font-weight: normal;
`
