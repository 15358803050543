// @flow

import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { Form as ReduxForm } from 'redux-form'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'

import Spacer from './Spacer'
import View from './View'
import H3 from './H3'
import Text from './Text'

const Wrapper = styled(Paper)`
  margin: 2em 0 12em 0;
  padding: 1em 2em;
`

const StyledForm = styled(ReduxForm)`
  display: flex;
  flex-direction: column;
`

type Props = {
  title: string,
  subtitle: string,

  containerProps?: any, // Props that will be sent to the container

  // Dialog
  dialog?: boolean, // Form will be shown in a <Dialog></Dialog> if true
  maxWidth?: number | string,
  open?: boolean, // Only takes effect if the form is a dialog
  onRequestClose?: Function, // Only takes effect if the form is a dialog
}

const Form = ({
  title,
  subtitle,
  containerProps,
  dialog,
  maxWidth,
  open,
  onRequestClose,
  ...rest
}: Props) => {
  if (dialog) {
    return (
      <Dialog
        {...containerProps}
        open={open}
        onRequestClose={onRequestClose}
        maxWidth={maxWidth}
      >
        <Paper
          style={{
            padding: '2em',
            textAlign: 'center',
          }}
        >
          <H3>{title}</H3>
          <Spacer top="0.5em" />
          <Text type="headline">{subtitle}</Text>
          <StyledForm {...rest} />
        </Paper>
      </Dialog>
    )
  } else {
    return (
      <View centerText {...containerProps}>
        <Spacer top="4em" />
        <H3>{title}</H3>
        <Spacer top="0.5em" />
        <Text type="headline" style={{ color: 'rgb(150, 150, 150)' }}>
          {subtitle}
        </Text>
        <Wrapper>
          <StyledForm {...rest} />
        </Wrapper>
      </View>
    )
  }
}

export default Form
