// @flow

import styled, { css } from 'styled-components'
import filtered from '../hocs/filtered'
import View from './View'

type Props = {
  top?: string,
  bottom?: string,
  left?: string,
  right?: string,
}

const Base = filtered(View, ['top', 'bottom', 'left', 'right'])

const toCSS = (input) => (typeof input === 'number' ? `${input}px` : input)

// prettier-ignore
export default styled(Base)`
  ${(props: Props) => props.top && css`
    margin-top: ${toCSS(props.top)};
  `}
  ${(props: Props) => props.bottom && css`
    margin-bottom: ${toCSS(props.bottom)};
  `}
  ${(props: Props) => props.left && css`
    margin-left: ${toCSS(props.left)};
  `}
  ${(props: Props) => props.right && css`
    margin-right: ${toCSS(props.right)};
  `}
`
