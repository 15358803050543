// @flow

import * as React from 'react'
import { formValues } from 'redux-form'

const InnerFormValues = formValues((props) => props.options)(
  ({ children, options, ...mappedValues }) => children(mappedValues)
)

export type Props = {
  values: string | string[] | { [propName: string]: true | string },
}

// If a form has a field named `options` and someone tries to access it through this, it will get destructured out, which will be confusing.
// We want to fail immediately and visibly, so we throw this error when we detect a potential problem.
const badPropNameError = new Error(`Prop name cannot be 'options'.`)

// Maps form values to render prop.
// Ex. `values={'fieldName'}` or `values={[ 'fieldName', 'otherField' ]}` or `values={{ field: true, otherField: 'actualFieldName' }}`
// Basically, the `formValues` function from `redux-form` but as a render prop component with some shiny bits attached. Render props FTW.
const FormValues = ({ children, values }: Props) => {
  const options = {}

  if (typeof values === 'string') {
    if (values === 'options') throw badPropNameError
    options[values] = values
  } else if (Array.isArray(values)) {
    for (const name of values) {
      if (name === 'options') throw badPropNameError
      options[name] = name
    }
  } else {
    for (const propName of Object.keys(values)) {
      if (propName === 'options') throw badPropNameError
      options[propName] =
        values[propName] === true ? propName : values[propName]
    }
  }

  return <InnerFormValues children={children} options={options} />
}

export default FormValues
