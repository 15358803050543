// @flow

import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

type Location = {
  pathname: string,
  search: string,
  hash: string,
  state: Object,
}

type Props = {
  title: string,
  caps?: boolean,
  to: string | Location,
  color?: string,
  noUnderline?: boolean,
}

// prettier-ignore
export default styled(Link)`
  ${(props: Props) => props.color && css`
    color: ${props.color};
  `}
  ${(props: Props) => props.noUnderline && css`
    text-decoration: none;
  `}
`;
