import { compose, mapProps } from 'recompose'
import { withCookies } from 'react-cookie'

import { tokenCookieName, idCookieName } from '../config'

export const withAuthToken = (propName = 'token') =>
  compose(
    withCookies,
    mapProps(({ cookies, ...props }) => ({
      ...props,
      [propName]: cookies.get(tokenCookieName),
    }))
  )

export const isAuthorized = ({ allCookies }) =>
  allCookies[idCookieName] === '7460b01e-59bc-4e0b-aac5-44e423aa46d7' ||
  allCookies[idCookieName] === '8442cd58-fa5e-43f7-9e92-7784e37a5973'
// this is merely a placeholder, we will implement a thorough authentication once Jack implements the Auth.
