// @flow

import { withStateHandlers } from 'recompose'

export const dialogControl = ({
  propName = 'dialogOpen',
  openName = 'openDialog',
  closeName = 'closeDialog',
  defaultValue = false,
}: {
  propName?: string,
  openName?: string,
  closeName?: string,
  defaultValue?: boolean,
} = {}) =>
  withStateHandlers(
    {
      [propName]: defaultValue,
    },
    {
      [openName]: () => () => ({ [propName]: true }),
      [closeName]: () => () => ({ [propName]: false }),
    }
  )
