// @flow

import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'

const tooltipColor = grey[700]
const accelerate = 'cubic-bezier(0.4, 0.0, 1, 1)'
const decelerate = 'cubic-bezier(0.0, 0.0, 0.2, 1)'
const tooltipDelay = 500

type Props = {
  style?: Object,
  show: boolean,
  position: string,
  onClick: (Event) => void,
  onMouseEnter: (SyntheticEvent<*>) => void,
  onMouseLeave: (SyntheticEvent<*>) => void,
  keepTooltip: boolean,
  onClick: (SyntheticEvent<*>) => void,
  fab: boolean,
  progress: string,
  classes: any,
  tooltip: any,
  tooltipPosition: any,
  progress: any,
  progressStyle: any,
  children: any,
}

type State = {
  hover: boolean,
}

// prettier-ignore
const Tooltip = styled.div`
  position: absolute;
  ${props => {
    const { show, position } = props
    const percentage = show ? 100 : 30

    switch (position) {
      default:
        return ''
      case 'top':
        return `bottom: ${percentage}%; left: 50%;`
      case 'bottom':
        return `top: ${percentage}%; left: 50%;`
      case 'left':
        return `left: ${percentage}%; top: 50%;`
      case 'right':
        return `left: ${percentage}%; top: 50%;`
    }
  }}
  transform: ${props => {
      const { show, position } = props
      const offset = show ? 8 : 0

      switch (position) {
        default:
          return 'none'
        case 'top':
          return `translateY(${-offset}px) translateX(-50%);`
        case 'bottom':
          return `translateY(${offset}px) translateX(-50%);`
        case 'left':
          return `translateX(${-offset}px) translateY(-50%);`
        case 'right':
          return `translateX(${offset}px) translateY(-50%);`
      }
    }}
  z-index: 10;
  font-size: 12px;
  font-weight: 200;
  white-space: nowrap;

  border-radius: 2px;
  padding: 8px;

  color: white;
  background-color: ${tooltipColor};
  opacity: ${props => (props.show ? '0.9' : '0')};
  transition: all ${props => (props.show ? decelerate : accelerate)} 0.2s;
  text-transform: none;
`

const styles = {
  linearProgress: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
  },
  circularProgress: {
    position: 'absolute',
    top: -2,
    left: -2,
    width: 60,
    height: 60,
  },
}

class AdvancedButton extends PureComponent<Props, State> {
  static defaultProps = {
    tooltipPosition: 'top',
  }

  removeTooltipDelay = undefined

  constructor(props: Props) {
    super(props)

    this.state = {
      hover: false,
    }
  }

  onMouseEnter = (event: SyntheticEvent<*>) => {
    const { onMouseEnter } = this.props
    onMouseEnter && onMouseEnter(event)
    this.setState({
      hover: true,
    })

    if (this.removeTooltipDelay) {
      clearTimeout(this.removeTooltipDelay)
    }
  }

  onMouseLeave = (event: SyntheticEvent<*>) => {
    const { onMouseLeave } = this.props
    onMouseLeave && onMouseLeave(event)

    this.removeTooltipDelay = setTimeout(() => {
      this.setState({
        hover: false,
      })
      this.removeTooltipDelay = undefined
    }, tooltipDelay)
  }

  onClick = (event: SyntheticEvent<*>) => {
    const { onClick, keepTooltip } = this.props
    onClick && onClick(event)

    if (!keepTooltip) {
      this.setState({
        hover: false,
      })
    }
  }

  getProgress = () => {
    const { classes, progress, progressStyle, fab } = this.props

    if (!progress) return null

    const progressProps = {
      mode: typeof progress === 'number' ? 'determinate' : 'indeterminate',
      style: progressStyle || {},
      value: progress,
    }

    if (fab) {
      return (
        <CircularProgress
          size={60}
          className={classes.circularProgress}
          {...progressProps}
        />
      )
    } else {
      return (
        <LinearProgress className={classes.linearProgress} {...progressProps} />
      )
    }
  }

  render() {
    const {
      tooltip,
      tooltipPosition,
      keepTooltip,
      progress,
      progressStyle,
      classes,
      children,
      ...buttonProps
    } = this.props

    buttonProps.style = buttonProps.style || {
      position: 'relative',
    }

    if (tooltip || progress) {
      // Check style
      const { position } = buttonProps.style
      if (
        position !== 'relative' &&
        position !== 'absolute' &&
        position !== 'fixed'
      ) {
        console.warn(
          'An AdvancedButton must be positioned when given a tooltip or a progress bar. Falling back to relative positioning...'
        )
        // $FlowFixMe
        buttonProps.style.position = 'relative'
      }
    }

    if (tooltip) {
      if (buttonProps.disabled) {
        console.warn(
          'A disabled button with tooltip has strange behaviours. Please refrain from doing this until the issues are resolved.'
        )
      }

      return (
        <Button
          {...buttonProps}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onClick={this.onClick}
        >
          {children}
          <Tooltip show={this.state.hover} position={tooltipPosition}>
            {tooltip}
          </Tooltip>
          {this.getProgress()}
        </Button>
      )
    } else {
      return (
        <Button
          {...buttonProps}
          className={
            buttonProps.raised || buttonProps.fab ? classes.whiteButton : ''
          }
          onClick={this.onClick}
        >
          {children}
          {this.getProgress()}
        </Button>
      )
    }
  }
}

export default withStyles(styles, { name: 'AdvancedButton' })(AdvancedButton)
