// @flow

import styled from 'styled-components'

type Props = {
  type: string,
}

export default styled.i.attrs({
  className: (props: Props) => `em em-${props.type}`,
})`
  color: inherit;
`
