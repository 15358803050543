// @flow

import React from 'react'
import MuiTextField from '@material-ui/core/TextField'
import { orange, red } from '@material-ui/core/colors'

type Props = {
  // User specified
  label: String,
  type: String,
  hint: String,

  // Injected by Redux Form
  input: Object,
  meta: Object,
}

const TextField = ({ input, label, hint, type, meta, inputProps }: Props) => (
  <MuiTextField
    {...input}
    defaultValue={meta && meta.initial}
    inputProps={inputProps}
    floatingLabelText={label}
    hintText={hint}
    type={type}
    errorText={meta && meta.touched && (meta.error || meta.warning || '')}
    errorStyle={
      meta && meta.warning ? { color: orange[500] } : { color: red[500] }
    }
  />
)

export default TextField
