// @flow

import React from 'react'
import Link from './Link'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

type Props = {
  title: string,
  description: string,
  to: string,
  icon: React$Element<*>,
}

const NavItem = (props: Props) => (
  <ListItem button component={Link} to={props.to}>
    <ListItemIcon>{props.icon}</ListItemIcon>
    <ListItemText primary={props.title} secondary={props.description} />
  </ListItem>
)

export default NavItem
