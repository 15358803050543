import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import CircularProgress from '@material-ui/core/CircularProgress'
import CheckIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'

export const STATUS = {
  /** Initial status */
  INITIAL: 'initial',
  /** Fetch request sent, response not received yet */
  LOADING: 'loading',
  /** Fetch successful, response received */
  READY: 'ready',
  /** Error when fetching */
  ERROR: 'error',
}

export default class StatusIndicator extends PureComponent {
  static propTypes = {
    status: PropTypes.oneOf(Object.values(STATUS)),
  }

  render() {
    const { status, ...iconProps } = this.props
    const iconStyle = iconProps.style || {}

    switch (status) {
      default:
      case STATUS.INITIAL:
        return null
      case STATUS.LOADING:
        return <CircularProgress size={24} {...iconProps} />
      case STATUS.READY:
        return (
          <CheckIcon
            {...iconProps}
            style={{
              color: 'green',
              ...iconStyle,
            }}
          />
        )
      case STATUS.ERROR:
        return (
          <ErrorIcon
            {...iconProps}
            style={{
              color: 'red',
              ...iconStyle,
            }}
          />
        )
    }
  }
}
