import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import ProgressBar from './ProgressBar'
import View from './View'
import Flex from './Flex'

const Overlay = View.extend`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
`
const signFile = gql`
  mutation signFile($filename: String!, $contentType: String!) {
    s3Sign(filename: $filename, contentType: $contentType) {
      signedUrl
      fileKey
      publicUrl
      success
    }
  }
`
class DropzoneS3Uploader extends Component {
  static propTypes = {
    server: PropTypes.string,
    s3Url: PropTypes.string,
    signingUrl: PropTypes.string,
    signingUrlQueryParams: PropTypes.object,
    signingUrlHeaders: PropTypes.object,

    children: PropTypes.element,
    headers: PropTypes.object,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    filename: PropTypes.string,
    maxFileSize: PropTypes.number,

    style: PropTypes.object,
    activeStyle: PropTypes.object,
    rejectStyle: PropTypes.object,
    imageStyle: PropTypes.object,
    overlay: PropTypes.func,

    onError: PropTypes.func,
    onProgress: PropTypes.func,
    onFinish: PropTypes.func, // Called with (info), where info = {filename: string} // TODO what other fields are there?
  }

  constructor(props) {
    super(props)

    this.state = {
      progress: null,
      error: null,
      hover: false,
    }
  }

  onProgress = (progress) => {
    const { onProgress } = this.props
    if (onProgress) onProgress(progress)
    this.setState({ progress })
  }

  onError = (err) => {
    const { onError } = this.props
    if (onError) onError(err)
    this.setState({ error: err })
  }

  onFinish = (info, files) => {
    const { onFinish } = this.props
    if (onFinish) onFinish(info, files)
    this.setState({ filename: info.filename, error: null, progress: null })
  }

  onMouseEnter = () => {
    this.setState({
      hover: true,
    })
  }

  onMouseLeave = () => {
    this.setState({
      hover: false,
    })
  }

  static isImage = (filename) => {
    return filename && filename.match(/\.(jpeg|jpg|gif|png)/gi)
  }

  handleDrop = (files) => {
    let error = null
    const size = files[0].size
    const { maxFileSize } = this.props

    if (!this.props.multiple && files.length > 1) {
      error = 'Only drop one file'
    } else if (maxFileSize && size > maxFileSize) {
      const sizeKB = (size / 1024 / 1024).toFixed(2)
      const maxKB = (maxFileSize / 1024 / 1024).toFixed(2)
      error = `Files nust be smaller than ${maxKB}kb. Yours is ${sizeKB}kb`
    }
    this.setState({ error })
    if (error) return

    this.props.client
      .mutate({
        mutation: signFile,
        variables: {
          filename: files[0].name,
          contentType: files[0].type,
        },
      })
      .then(({ data }) => {
        const { signedUrl, publicUrl, fileKey, success } = data.s3Sign
        if (!success) this.onError('Failed to sign file')
        else {
          const promise = new Promise((resolve, reject) => {
            const uploadRequest = new XMLHttpRequest()
            uploadRequest.open('PUT', signedUrl, true)
            uploadRequest.setRequestHeader('Content-Type', files[0].type)

            uploadRequest.upload.onprogress = (event) => {
              if (event.lengthComputable) {
                this.onProgress(event.loaded / event.total)
              }
            }

            uploadRequest.onload = () => {
              if (uploadRequest.status === 200) {
                resolve(fileKey)
              } else {
                reject()
              }
            }

            uploadRequest.onerror = () => {
              reject()
            }

            uploadRequest.send(files[0])
          }).then((fileKey) => {
            this.setState({ filename: fileKey, error: null, progress: null })
            this.onFinish({ filename: fileKey, signedUrl, publicUrl }, files[0])
          })
          promise.catch(this.onError('Failed to upload file'))
        }
      })
  }

  getContent = () => {
    const filename =
      typeof this.props.filename === 'string'
        ? this.props.filename
        : this.state.filename
    const { children, overlay, s3Url, placeholder, label } = this.props
    const { progress, error } = this.state
    const fileUrl = filename
      ? filename.includes(s3Url)
        ? filename
        : `${s3Url}/${filename}`
      : null

    const contentStyle = {
      display: 'block',
      position: 'relative',
      width: '100%',
      height: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }

    let preview = null
    if (children) {
      preview = React.cloneElement(React.Children.only(children), {
        filename,
        progress,
        error,
        fileUrl,
        s3Url,
      })
    } else if (fileUrl) {
      if (DropzoneS3Uploader.isImage(fileUrl)) {
        preview = (
          <img
            src={fileUrl}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
            alt="Dropzone"
          />
        )
      } else {
        preview = (
          <div>
            <span
              className="glyphicon glyphicon-file"
              style={{ fontSize: 50 }}
            />
            {filename}
          </div>
        )
      }
    } else if (filename) {
      preview = (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -13,
            marginLeft: -13,
          }}
        >
          <span className="glyphicon glyphicon-file" style={{ fontSize: 26 }} />
          {filename}
        </div>
      )
    } else {
      preview = (
        <Flex
          column
          align="center"
          justify="center"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {placeholder || label}
        </Flex>
      )
    }

    return (props) => (
      <Flex style={contentStyle} align="center" justify="center">
        {preview}
        <Overlay>
          {overlay && overlay({ ...props, hover: this.state.hover })}
        </Overlay>
        {progress ? (
          <ProgressBar now={progress} label="%(percent)s%" srOnly />
        ) : null}
        {error ? <small>{error}</small> : null}
      </Flex>
    )
  }

  render() {
    // Get the props that should be passed to dropzone
    // noinspection JSUnusedLocalSymbols
    const {
      server,
      s3Url,
      signingUrl,
      signingUrlQueryParams,
      signingUrlHeaders,
      children,
      headers,
      multiple,
      accept,
      filename,
      maxFileSize,
      style,
      activeStyle,
      rejectStyle,
      imageStyle,
      overlay,
      onError,
      onProgress,
      onFinish,
      ...others
    } = this.props

    const dropzoneProps = {
      style: {
        height: '300px',
        width: '100%',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: '#bbbbbb',
        borderRadius: 3,
        position: 'relative',
        cursor: 'pointer',
        textAlign: 'center',
        display: 'inline-block',
        ...(this.props.style || {}),
      },
      activeStyle: {
        borderStyle: 'solid',
        backgroundColor: '#eee',
        ...(this.props.activeStyle || {}),
      },
      rejectStyle: {
        borderStyle: 'solid',
        backgroundColor: '#ffdddd',
        ...(this.props.rejectStyle || {}),
      },
      multiple: this.props.multiple || false,
      accept: this.props.accept,
      ...others,
    }

    return (
      <Dropzone
        onDrop={this.handleDrop}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        {...dropzoneProps}
      >
        {this.getContent()}
      </Dropzone>
    )
  }
}

export default withApollo(DropzoneS3Uploader)
