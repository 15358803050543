import * as React from 'react'

import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import Text from '@material-ui/core/Typography'

import { Flex } from '..'

const RadioSwitch = ({ column, input, label, options }) => (
  <Flex align="center">
    <FormLabel>{label}</FormLabel>
    <Flex column={column}>
      {options.map((option) => {
        return (
          <Flex align="center">
            <Radio
              color="default"
              value={option}
              checked={input.value === option}
              onChange={() =>
                label
                  ? input.onChange(option, label.toLowerCase())
                  : input.onChange(option)
              }
            />
            <Text>
              {option === true ? 'Yes' : option === false ? 'No' : option}
            </Text>
          </Flex>
        )
      })}
    </Flex>
  </Flex>
)

export default RadioSwitch
