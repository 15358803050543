// @flow

import styled, { css } from 'styled-components'
import filtered from '../hocs/filtered'
import { Box } from 'reflexbox/styled-components'

type Props = {
  centerText?: boolean,
  grow?: number,
  fullWidth?: boolean,
}

const Base = filtered(Box, ['centerText', 'grow', 'fullWidth'])

// prettier-ignore
const View = styled(Base)`
  ${(props: Props) => props.centerText && css`
    text-align: center;
  `}
  ${(props: Props) => props.overflow && css`
    overflow: ${props.overflow};
  `}
  ${(props: Props) => props.fullWidth && css`
    width: 100%;
  `}
`;

export default View
