export default {
  CREATE_BANNER_START: 'CREATE_BANNER_START',
  CREATE_BANNER_END: 'CREATE_BANNER_END',
  CREATE_BANNER_RESET: 'CREATE_BANNER_RESET',
  CACHE_BANNER: 'CACHE_BANNER',
  CLEAR_BANNER: 'CLEAR_BANNER',

  FETCH_BANNERS_START: 'FETCH_BANNERS_START',
  FETCH_BANNERS_END: 'FETCH_BANNERS_END',

  SAVE_BANNER_START: 'SAVE_BANNER_START',
  SAVE_BANNER_END: 'SAVE_BANNER_END',
  SAVE_BANNER_RESET: 'SAVE_BANNER_RESET',

  SWAP_BANNER_START: 'SWAP_BANNER_START',
  SWAP_BANNER_END: 'SWAP_BANNER_END',

  ARCHIVE_BANNER_START: 'ARCHIVE_BANNER_START',
  ARCHIVE_BANNER_END: 'ARCHIVE_BANNER_END',
  ARCHIVE_BANNER_RESET: 'ARCHIVE_BANNER_RESET',
}
