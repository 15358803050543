import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { apiUrl, tokenCookieName } from './config'
import { redirectToLogin } from './containers/App/App.js'

const httpLink = createHttpLink({
  uri: apiUrl + '/graphql',
})
const regex = new RegExp(`${tokenCookieName}=([\\w.-]*)`)

const getToken = () => {
  const cookies = document.cookie.toString()
  return regex.exec(cookies)[1]
}

let token
const authLink = setContext((_, { headers }) => {
  // if you have a cached value, return it immediately
  if (!token) token = getToken()

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      'cache-control': 'no-cache,no-store',
    },
  }
})

const resetToken = onError(({ networkError }) => {
  if (
    networkError &&
    networkError.name === 'ServerError' &&
    networkError.statusCode === 401
  ) {
    redirectToLogin()
  }
})

export const client = new ApolloClient({
  link: authLink.concat(resetToken).concat(httpLink),
  cache: new InMemoryCache(),
})
