const defaultState = {
  title: '',
  breadcrumbs: {
    // Maps path components to breadcrumb title
    // Some special, default values
    add: 'New',
    edit: 'Edit',
    reorder: 'Reorder',
    lessonPlanFileUploader: 'File Uploader',
    curriculum: 'Curriculum',
  },
}

const reducer = (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'SET_APP_TITLE':
      return {
        ...state,
        title: payload,
      }
    case 'ADD_BREADCRUMB':
      return {
        ...state,
        breadcrumbs: {
          ...state.breadcrumbs,
          ...payload,
        },
      }
    default:
      return state
  }
}

export default reducer
