// @flow

import React from 'react'
import { ConnectedRouter } from 'react-router-redux'
import { Switch, Route } from 'react-router-dom'

import {
  App,
  Banner,
  Classroom,
  Course,
  Curriculum,
  FileUploader,
  HomePage,
  CenterLocation,
  LoginPage,
  LessonPlanFile,
  LessonPlanFileUploader,
  ProblemSetGraphViewer,
  NotFound,
  Student,
  Employee,
  Reports,
  Script,
} from './containers'

const createRouter = (history: History) => (
  <ConnectedRouter history={history}>
    <App>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/login" component={LoginPage} />

        <Route path="/banner" component={Banner} />
        <Route
          path="/lessonPlanFileUploader"
          component={LessonPlanFileUploader}
        />
        <Route path="/lessonPlanFile" component={LessonPlanFile} />
        <Route path="/fileUploader" component={FileUploader} />
        <Route
          path="/problemSetGraphViewer"
          component={ProblemSetGraphViewer}
        />
        {/* Model Routes */}
        <Route path="/classroom" component={Classroom} />
        <Route path="/course" component={Course} />
        <Route path="/centerLocation" component={CenterLocation} />
        <Route path="/student" component={Student} />
        <Route path="/script" component={Script} />
        <Route path="/employee" component={Employee} />
        <Route path="/curriculum" component={Curriculum} />
        <Route path="/reports" component={Reports} />
        <Route component={NotFound} />
      </Switch>
    </App>
  </ConnectedRouter>
)

export default createRouter
