// @flow

import * as React from 'react'

function filtered(
  Component: React.ComponentType<any>,
  filteredProps: string[]
) {
  const FilteredComponent = (props: any) => {
    const newProps = Object.assign({}, props)
    filteredProps.forEach((propName) => {
      delete newProps[propName]
    })
    return <Component {...newProps} />
  }
  return FilteredComponent
}

export default filtered
