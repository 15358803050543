// @flow

import { Component } from 'react'

type Selector = (state: { [string]: any }) => any

type Props = {
  /**
   * The header cell for this column.
   * This can either be a string, a React element, or a function that generate
   * a React element. Passing in a string will render a default header cell with
   * that string.
   *
   * Stateless functions will be called with: `header(props)`.
   */
  Header: React$Node | React$Component<any, any>,

  /**
   * The body cell that will display data for each Model.
   * This can either be a string, or a React element. Passing in a string will
   * render a default data cell with that string. The React element will receive
   * the following props:
   *
   * ```
   * props: {
   *   model: object,       // The raw data of the model being rendered.
   *   ...mapStateToProps() // Additional Redux state (see below)
   * }
   * ```
   *
   * Stateless functions will be called with: `cell(props)`.
   */
  Cell: React$Component<any, any>,

  /**
   * Mirrors the `mapStateToProps` of react-redux's `connect()`.
   * Use this to feed the cell component more information of the Redux state.
   */
  mapStateToProps?: Selector,

  /**
   * Mirrors react-table's `accessor` props.
   * Use this to pick out only the data that you need for Cell
   */
  accessor?: string | ((any) => any),
}

/**
 * Column is used to display each column inside a ModelTable. This component
 * should be specified in the `children` of a ModelTable. The order in which
 * you specify Columns is the exact order in which they will be displayed.
 *
 * Example:
 * ```
 * <ModelTable ...>
 *   <Column header={...} cell={...} />
 *   <Column header={...} cell={...} />
 *   <Column header={...} cell={...} />
 * </ModelTable>
 * ```
 */
export default class Column extends Component<Props, void> {
  render() {
    console.error('A `<Column />` should never render!')
    return null
  }
}
