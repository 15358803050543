import * as React from 'react'
import { Component } from 'react'

import Image from './Image'

class FallbackImage extends Component {
  static getDerivedStateFromProps(newProps, state) {
    if (newProps.src !== state.src) {
      return {
        src: newProps.src,
        failed: !newProps.src,
      }
    }
  }

  render() {
    if (this.state.failed) {
      return <Image src={this.props.fallback} />
    } else {
      return (
        <Image
          onError={() => this.setState({ failed: true })}
          {...this.props}
        />
      )
    }
  }
}

export default FallbackImage
