import React, { Component } from 'react'
import PropTypes from 'prop-types'

import View from './View'
import Flex from './Flex'
import TooltipButton from './AdvancedButton'

const Indicator = Flex.extend.attrs({
  align: 'center',
  justify: 'center',
})`
  transition: all 0.2s ease;
  height: ${(props) => (props.show ? 12 : 0)}px;
  ${(props) => !props.show && 'max-width: 0px;'} overflow: hidden;
`

// Children will also have clicks and maxClicks injected as props
export default class MultiClickButton extends Component {
  static propTypes = {
    ...TooltipButton.propTypes,
    onConfirm: PropTypes.func.isRequired,
    maxClicks: PropTypes.number,
    tooltips: PropTypes.arrayOf(PropTypes.string), // Use false to explicitly disable tooltip at index
    countPosition: PropTypes.oneOf([
      'hidden',
      'top',
      'bottom',
      'left',
      'right',
    ]),
  }

  static defaultProps = {
    maxClicks: 4,
    countPosition: 'bottom',
  }

  constructor(props) {
    super(props)

    this.state = {
      clicks: 0,
      processing: false,
    }
  }

  asyncSetState = (newState) => {
    return new Promise((resolve) => this.setState(newState, resolve))
  }

  onClick = async (event) => {
    const { maxClicks, onClick, onConfirm } = this.props
    onClick && onClick(event)

    await this.asyncSetState((state) => ({
      clicks: state.clicks + 1,
    }))

    if (this.state.clicks >= maxClicks) {
      // Trigger passed onClick function and reset count.
      this.setState({
        clicks: 0,
        processing: true,
      })
      await onConfirm(event)
      this.setState({
        processing: false,
      })
    }
  }

  onBlur = (event) => {
    const { onBlur } = this.props

    onBlur && onBlur(event)
    this.setState({
      clicks: 0,
    })
  }

  getTooltip = () => {
    const { tooltips, tooltip } = this.props

    if (tooltips) {
      for (let i = this.state.clicks; i >= 0; i--) {
        if (tooltips[i] || tooltips[i] === false) return tooltips[i]
      }
    }

    return tooltip
  }

  getDirection = () => {
    switch (this.props.countPosition) {
      default:
      case 'bottom':
        return 'column'
      case 'top':
        return 'column-reverse'
      case 'left':
        return 'row-reverse'
      case 'right':
        return 'row'
    }
  }

  getCount = () => {
    const { clicks } = this.state
    const { maxClicks, fab } = this.props

    if (!clicks) {
      return null
    }

    if (fab) {
      return clicks ? `${clicks}/${maxClicks}` : ''
    } else {
      return clicks ? `(${clicks}/${maxClicks})` : ''
    }
  }

  mapChild = (child) => {
    const { maxClicks } = this.props
    const { clicks } = this.state
    if (typeof child === 'string') {
      return child
    } else {
      return React.cloneElement(child, { clicks, maxClicks })
    }
  }

  render() {
    const {
      children,
      disabled,
      maxClicks,
      tooltips,
      countPosition,
      ...buttonProps
    } = this.props
    const { clicks, processing } = this.state

    if (countPosition === 'hidden') {
      return (
        <TooltipButton
          progress={processing}
          keepTooltip
          {...buttonProps}
          tooltip={this.getTooltip()}
          disabled={processing || disabled}
          onClick={this.onClick}
          onBlur={this.onBlur}
        >
          {React.Children.map(children, this.mapChild)}
        </TooltipButton>
      )
    }

    return (
      <TooltipButton
        progress={processing}
        keepTooltip
        {...buttonProps}
        tooltip={this.getTooltip()}
        disabled={processing || disabled}
        onClick={this.onClick}
        onBlur={this.onBlur}
      >
        <Flex direction={this.getDirection()} align="center">
          <View>{React.Children.map(children, this.mapChild)}</View>
          <Indicator
            show={countPosition !== 'hidden' && Boolean(clicks)}
            style={
              this.getCount() &&
              (countPosition === 'left' || countPosition === 'right')
                ? { padding: '0em 0em 0em 0.3em' }
                : {}
            }
          >
            <span>{this.getCount()}</span>
          </Indicator>
        </Flex>
      </TooltipButton>
    )
  }
}
