import { delay } from 'redux-saga'
import { all, put, select, takeLatest } from 'redux-saga/effects'
import { push } from 'react-router-redux'

import types from './actions/types'

const normalDelay = 2500

function* back() {
  // Redirect to previous page
  const location = yield select((state) => state.router.location.state)
  const { from } = location || { from: { pathname: '/banner' } }
  yield put(push(from))
}

function* onCreateEnd(action) {
  if (action.error) return

  if (action.meta && action.meta.multi) {
    yield delay(500)
    yield put({
      type: types.CREATE_BANNER_RESET,
    })
    yield* back()
  } else {
    yield put({
      type: types.CREATE_BANNER_RESET,
    })
  }
}

function* onSaveEnd(action) {
  if (action.error) return

  yield delay(normalDelay)
  yield put({
    type: types.SAVE_BANNER_RESET,
  })
  yield* back()
}

function* onArchiveEnd(action) {
  if (action.error) return
  yield* back()
}

export default function* bannerSaga() {
  yield all([
    takeLatest(types.CREATE_BANNER_END, onCreateEnd),
    takeLatest(types.SAVE_BANNER_END, onSaveEnd),
    takeLatest(types.ARCHIVE_BANNER_END, onArchiveEnd),
  ])
}
