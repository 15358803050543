import React, { Component } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '../components'

// remove the fakeTags and replace with real tags when api change is made
const fakeTags = [
  'Math',
  'Science',
  'Hot',
  'Writing',
  'Olympiad',
  'Physics',
  'English',
]

class Tags extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      tags: [],
    }
  }

  handleChange = (event) => {
    const value = event.target.value
    if (value !== 'New Tag' && value.indexOf('New Tag') === -1) {
      this.setState({ tags: value })
      this.props.input.onChange(value)
    } else {
      this.handleClickOpen()
    }

    // this.props.input.onChange()
  }
  handleClickOpen = () => {
    this.setState({ open: true })
  }
  handleClose = () => {
    this.setState({ open: false })
  }
  handleSave = () => {
    // add some mutation to allow new tags to be created
    this.handleClose()
  }

  render() {
    const { tags } = this.state
    return (
      <FormControl>
        <InputLabel htmlFor="select-multiple-checkbox">Tag</InputLabel>
        <Select
          multiple
          value={tags}
          onChange={this.handleChange}
          renderValue={(selected) => selected.join(', ')}
        >
          <MenuItem key="newTag" value="New Tag">
            <ListItemText primary={'New Tag'} />
          </MenuItem>
          {fakeTags.map((fakeTag) => (
            <MenuItem key={fakeTag} value={fakeTag}>
              <Checkbox checked={tags.indexOf(fakeTag) > -1} color="primary" />
              <ListItemText primary={fakeTag} />
            </MenuItem>
          ))}
        </Select>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>New Tag</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter a new Tag</DialogContentText>
            <TextField label="New Tag" />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </FormControl>
    )
  }
}

export default Tags
