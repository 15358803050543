import { combineReducers } from 'redux'

import status from './status'
import list from './list'
import cache from './cache'

export default combineReducers({
  status,
  list,
  cache,
})
