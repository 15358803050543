import React from 'react'
import { Flex } from '.'
import Plotly from 'plotly.js-cartesian-dist'
import createPlotlyComponent from 'react-plotly.js/factory'
const Plot = createPlotlyComponent(Plotly)
const COLORS = ['#0088FE', '#00C49F', '#db301a', '#FF8042']

const BoxPlotComponent = ({
  q1,
  q3,
  mean,
  median,
  max,
  min,
  dataNames,
  title,
}) => {
  const data = []
  let i = 0
  for (const name of dataNames) {
    data.push({
      type: 'box',
      q1: [q1[i]],
      q3: [q3[i]],
      mean: [mean[i]],
      median: [median[i]],
      upperfence: [max[i]],
      lowerfence: [min[i]],
      x0: name,
      name,
      marker: { color: COLORS[i % 4] },
    })
    i++
  }

  return (
    <Flex column>
      {React.createElement(Plot, {
        data: data,
        layout: { width: 640, height: 480, title: title },
      })}
    </Flex>
  )
}

export default BoxPlotComponent
