import React from 'react'
import styled, { css } from 'styled-components'

// Used for common function pattern like props => props.flex ? `flex: ${props.flex};` : ''
const propMap = (propName, cssName) => (props) =>
  propName in props ? `${cssName}: ${props[propName]};` : ''

const margins = css`
  ${propMap('m', 'margin')}
  ${(props) =>
    props.mx ? `margin-left: ${props.mx}; margin-right: ${props.mx};` : ''}
  ${(props) =>
    props.my ? `margin-top: ${props.my}; margin-bottom: ${props.my};` : ''}
  ${propMap('mt', 'margin-top')}
  ${propMap('mr', 'margin-right')}
  ${propMap('mb', 'margin-bottom')}
  ${propMap('ml', 'margin-left')}
`

const paddings = css`
  ${propMap('p', 'padding')}
  ${(props) =>
    props.px ? `padding-left: ${props.px}; padding-right: ${props.px};` : ''}
  ${(props) =>
    props.py ? `padding-top: ${props.py}; padding-bottom: ${props.py};` : ''}
  ${propMap('pt', 'padding-top')}
  ${propMap('pr', 'padding-right')}
  ${propMap('pb', 'padding-bottom')}
  ${propMap('pl', 'padding-left')}
`

const Flex = styled(
  ({
    direction,
    column,
    flex,
    align,
    justify,
    grow,
    shrink,
    basis,
    alignSelf,
    justifySelf,
    overflow,
    m,
    mx,
    my,
    mt,
    mr,
    mb,
    ml,
    p,
    px,
    py,
    pt,
    pr,
    pb,
    pl,
    ...props
  }) => {
    return <div {...props} />
  }
)`
  display: flex;
  flex-direction: ${(props) => {
    if (props.direction) return props.direction
    return props.column ? 'column' : 'row'
  }};
  ${propMap('flex', 'flex')}
  ${propMap('align', 'align-items')}
  ${propMap('justify', 'justify-content')}
  ${propMap('grow', 'flex-grow')}
  ${propMap('shrink', 'flex-shrink')}
  ${propMap('basis', 'flex-basis')}
  ${propMap('alignSelf', 'align-self')}
  ${propMap('justifySelf', 'justify-self')}
  ${propMap('overflow', 'overflow')}
  ${margins}
  ${paddings}
`

Flex.displayName = 'Flex'

export default Flex
