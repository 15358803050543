// @flow
import { createMuiTheme } from '@material-ui/core/styles'
import { blue, red } from '@material-ui/core/colors'

const {
  REACT_APP_NODE_ENV,
  REACT_APP_API_HOST,
  REACT_APP_LOGIN_URL,
  REACT_APP_S3_BUCKET,
  REACT_APP_S3_REGION,
  REACT_APP_TOKEN_NAME,
  REACT_APP_ID_NAME,
} = process.env

// IMPORTANT: when running `npm build`, `NODE_ENV` is ALWAYS 'production'.
export const dev = REACT_APP_NODE_ENV !== 'production'

export const tokenCookieName = REACT_APP_TOKEN_NAME || 'ardentoken'
export const idCookieName = REACT_APP_ID_NAME || 'employeeId'

if (dev) {
  console.warn('Running in debug mode')
}

export const LOCAL_ARDENT_ACCOUNT_URL = 'http://localhost:3001/login'

export const apiUrl =
  REACT_APP_API_HOST ||
  (dev
    ? 'https://ardent-api-next.ardentlabs.io'
    : 'https://ardent-api.ardentlabs.io')

export const loginRedirect =
  REACT_APP_LOGIN_URL ||
  (dev
    ? 'https://account-next.ardentlabs.io/login'
    : 'https://account.ardentlabs.io/login')

const bucket = REACT_APP_S3_BUCKET || 'solve-app-dev'
const region = REACT_APP_S3_REGION || 'us-west-1'

export const s3Config = {
  bucket,
  region,
  url: `https://${bucket}.s3-${region}.amazonaws.com`,
}

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#00adef',
    },
    contrastThreshold: 2,
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
  },
})

export const rebassTheme = {
  primary: blue[500],
  accent: red['A400'],
  header: '#FFF',
  background: '#FAFAFA',
  font: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
}
