// @flow

import React, { Component } from 'react'
import styled from 'styled-components'
import { withApollo } from 'react-apollo'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

import gql from 'graphql-tag'
import { compose } from 'recompose'
import { withCookies } from 'react-cookie'
import * as Cookies from 'js-cookie'

import { Spacer, Flex, NavDrawer, Footer } from '../../components'
import AppBar from './AppBar'

import {
  LOCAL_ARDENT_ACCOUNT_URL,
  loginRedirect,
  dev,
  tokenCookieName,
  idCookieName,
} from '../../config'
import { dialogControl } from '../../utils/dialog'

export const redirectToLogin = (props) => {
  const options = {
    domain:
      process.env.NODE_ENV !== 'production' ? 'localhost' : 'ardentlabs.io',
    path: '/',
  }
  Cookies.remove(tokenCookieName, options)
  Cookies.remove(idCookieName, options)
  window.location.replace(
    (process.env.NODE_ENV !== 'production'
      ? LOCAL_ARDENT_ACCOUNT_URL
      : loginRedirect) + `?from=${encodeURIComponent(window.location.href)}`
  )
}

const Page = styled(Flex).attrs({
  align: 'stretch',
  justify: 'stretch',
})`
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`

const authMutation = gql`
  mutation Auth($token: String!) {
    employeeVerify(token: $token) {
      success
      token
    }
  }
`

type Props = {
  children: React$Element<*>,
  cookies: any,
  dialogOpen: boolean,
  openDialog: () => void,
  closeDialog: () => void,
  client: any,
}

type State = {
  navToggled: boolean,
  windowWidth: number,
  loading: boolean,
}

class App extends Component<Props, State> {
  state = {
    navToggled: false,
    windowWidth: 0,
    loading: true,
  }

  openNav = () => {
    this.setState({ navToggled: true })
  }

  closeNav = () => {
    this.setState({ navToggled: false })
  }

  toggleNav = () => {
    this.setState((state) => ({
      navToggled: !state.navToggled,
    }))
  }

  toLiveBuild = () => {
    window.location = 'https://hq.ardentlabs.io'
  }

  componentDidMount() {
    const { cookies, client } = this.props

    const token = cookies.get(tokenCookieName) || ''

    if (!token) {
      redirectToLogin(this.props)
    }

    client
      .mutate({
        mutation: authMutation,
        variables: {
          token,
        },
      })
      .then(({ data, errors }) => {
        if (errors || !data || !data.employeeVerify.success) {
          redirectToLogin(this.props)
        } else {
          this.setState({
            loading: false,
          })
          cookies.set(tokenCookieName, data.employeeVerify.token, {
            domain:
              process.env.NODE_ENV !== 'production'
                ? 'localhost'
                : 'ardentlabs.io',
            path: '/',
          })
        }
      })
  }

  render() {
    const { dialogOpen, closeDialog } = this.props
    const { navToggled, loading } = this.state

    if (loading) {
      return <CircularProgress />
    }

    return (
      <Page>
        <Flex column grow={1}>
          <AppBar openMenu={this.toggleNav} />
          <NavDrawer isOpen={navToggled} close={this.closeNav} />
          <Flex grow={1} column align="center" overflow="auto">
            <Spacer top={'1em'} />
            {this.props.children}
          </Flex>
          <Footer />
        </Flex>
        <Dialog open={dialogOpen}>
          <DialogTitle>DEV Build</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are running a development build of Ardent HQ. This website is
              intended for testing only. Any changes you make on this website is
              ephermal and may be lost. Proceed only if you are a developer or a
              tester.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Proceed</Button>
            <Button color="primary" onClick={this.toLiveBuild}>
              Go to live build
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    )
  }
}

export default compose(
  withCookies,
  withApollo,
  dialogControl({ defaultValue: dev && process.env.NODE_ENV === 'production' })
)(App)
