// @flow

import React from 'react'
import Link from './Link'

import { withStyles } from '@material-ui/core/styles' // TODO (Sam): I hate it
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import IconPerson from '@material-ui/icons/Person'

import { s3Config } from '../config'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import * as Cookies from 'js-cookie'
import { idCookieName } from '../config'
import { redirectToLogin } from '../containers/App/App'

const styleSheet = {
  listFull: {
    width: 'auto',
    flex: 'initial',
  },
}

const GET_EMPLOYEE = gql`
  query get_employee($id: ID!) {
    employee(id: $id) {
      firstName
      lastName
      image
    }
  }
`

const NavProfile = (props) => (
  <Query query={GET_EMPLOYEE} variables={{ id: Cookies.get(idCookieName) }}>
    {({ data, loading, error }) => {
      const user = !error ? { ...data.employee } : null
      if (loading) return <h1>Loading...</h1>
      return (
        <List className={props.classes.listFull}>
          <ListItem
            button
            component={Link}
            to={''}
            onClick={() => redirectToLogin()}
          >
            <ListItemAvatar>
              {user && user.image ? (
                <Avatar src={`${s3Config.url}/${user.image}`} />
              ) : (
                <Avatar>
                  <IconPerson />
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={user ? user.firstName + ' ' + user.lastName : 'Log in'}
              secondary={
                user ? 'Click here to log out' : 'Access restricted pages'
              }
            />
          </ListItem>
        </List>
      )
    }}
  </Query>
)

export default withStyles(styleSheet, { name: 'NavProfile' })(NavProfile)
