import React from 'react'

import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'

import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'

import IconHome from '@material-ui/icons/Home'
import IconPeople from '@material-ui/icons/People'
import IconBooks from '@material-ui/icons/LibraryBooks'
import IconLibrary from '@material-ui/icons/LocalLibrary'
import IconView from '@material-ui/icons/ViewModule'
import IconLocation from '@material-ui/icons/Place'
import IconFileUpload from '@material-ui/icons/CloudUpload'
import IconViewList from '@material-ui/icons/ViewList'
import IconWork from '@material-ui/icons/Work'
import IconGraph from '@material-ui/icons/GraphicEq'
import IconBarChart from '@material-ui/icons/BarChart'
import CodeIcon from '@material-ui/icons/Code'
import View from './View'
import NavItem from './NavItem'
import NavProfile from './NavProfile'
import { isAuthorized } from '../utils/auth'

const Container = View.extend`
  overflow-y: auto;
  flex-grow: 1;
`

let NavList = (props) => (
  <Container>
    <NavProfile close={props.close} />
    <Divider />
    <List>
      <NavItem
        title="Home"
        description="Your main dashboard"
        to="/"
        icon={<IconHome />}
        close={props.close}
      />
      <NavItem
        title="Curriculum"
        description="Manage curriculums for students"
        to="/curriculum"
        icon={<IconViewList />}
        close={props.close}
      />
      <NavItem
        title="Courses"
        description="Create and modify course content"
        to="/course"
        icon={<IconBooks />}
        close={props.close}
      />
      <NavItem
        title="Attachments"
        description="Upload lesson and assignment files"
        to="/lessonPlanFileUploader"
        icon={<IconFileUpload />}
        close={props.close}
      />
      <NavItem
        title="ProblemSetGraph"
        description="Look up the problemSetGraph"
        to="/problemSetGraphViewer"
        icon={<IconGraph />}
        close={props.close}
      />
      <NavItem
        title="Classrooms"
        description="Manage your classes"
        to="/classroom"
        icon={<IconLibrary />}
        close={props.close}
      />
      {isAuthorized(props) ? (
        <NavItem
          title="Students"
          description="View students and their profiles"
          to="/student"
          icon={<IconPeople />}
          close={props.close}
        />
      ) : null}
      {isAuthorized(props) ? (
        <NavItem
          title="Employees"
          description="View employees and their profiles"
          to="/employee"
          icon={<IconWork />}
          close={props.close}
        />
      ) : null}
      <NavItem
        title="Branches"
        description="Manage and edit branch information"
        to="/centerLocation"
        icon={<IconLocation />}
        close={props.close}
      />
      <NavItem
        title="Banners"
        description="View and edit banners"
        to="/banner"
        icon={<IconView />}
        close={props.close}
      />
      <NavItem
        title="Reports"
        description="View course reports"
        to="/reports"
        icon={<IconBarChart />}
        close={props.close}
      />
      <NavItem
        title="Scripts"
        description="View scripts and their info"
        to="/script"
        icon={<CodeIcon />}
        close={props.close}
      />
    </List>
  </Container>
)

NavList = connect(
  (state) => ({
    user: state.auth.employee,
  }),
  {
    logout: () => ({ type: 'LOGOUT' }),
  }
)(withCookies(NavList))

export default NavList
