import deepmerge from 'deepmerge'
import { normalize, schema } from 'normalizr'

import types from '../actions/types'

const bannerEntity = new schema.Entity('banners')

const defaultState = {}

export default (state = defaultState, action) => {
  // eslint-disable-next-line
  const { type, payload, meta, error } = action

  let diff = {}

  switch (type) {
    default: {
      return state
    }
    case types.CREATE_BANNER_END: {
      if (payload) {
        const uploadedBanners = Object.values(payload)
        diff = normalize(uploadedBanners, [bannerEntity]).entities.banners
      }
      break
    }
    case types.FETCH_BANNERS_END: {
      if (payload) {
        diff = normalize(payload, { banners: [bannerEntity] }).entities.banners
      }
      break
    }
    case types.SAVE_BANNER_END: {
      if (payload) {
        diff = normalize(payload, { banner: bannerEntity }).entities.banners
      }
      break
    }
    case types.ARCHIVE_BANNER_END: {
      if (payload) {
        // Remove banner by destructuring
        // eslint-disable-next-line
        const { [payload.banner.id]: _, ...others } = state
        state = others
      }
      break
    }
    case types.SWAP_BANNER_END: {
      if (payload) {
        if (meta && meta.overwrite) {
          diff = normalize(payload, { banner1: bannerEntity }).entities.banners
        } else {
          diff = normalize(payload, {
            banner1: bannerEntity,
            banner2: bannerEntity,
          }).entities.banners
        }
      }
      break
    }
  }

  return deepmerge(state, diff || {})
}
