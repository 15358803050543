import React, { Component } from 'react'
import { Query } from 'react-apollo'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Flex,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  RadioSwitch,
  Select,
} from '../components'

class Audience extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      recipients: 'All',
      fromGrade: 0,
      toGrade: 12,
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleOnClick = () => {
    this.setState({ open: true })
  }

  handleRadioChange = (option) => {
    this.setState({ recipients: option })
  }

  render() {
    const GradeSelector = ({ name, title, value }) => {
      const grades = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      return (
        <FormControl fullWidth>
          <InputLabel>{title}</InputLabel>
          <Select name={name} value={value} onChange={this.handleChange}>
            {grades.map((grade) => (
              <MenuItem value={grade}>{`${
                grade === 0 ? 'K' : grade
              }`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }

    const { fromGrade, open, recipients, toGrade } = this.state
    return (
      <FormControl>
        <List>
          <ListItem divider disabled>
            <ListItemText primary="Audience" />
          </ListItem>
          <ListItem button divider onClick={this.handleOnClick}>
            <ListItemText primary="Recipients" secondary={recipients} />
          </ListItem>
          <ListItem button divider onClick={this.handleOnClick}>
            <ListItemText
              primary="Grades"
              secondary={`${fromGrade === 0 ? 'K' : fromGrade} - ${
                toGrade === 0 ? 'K' : toGrade
              }`}
            />
          </ListItem>
        </List>
        <Dialog fullWidth open={open} onClose={this.handleClose}>
          <DialogTitle>Filter Audience</DialogTitle>
          <DialogContent>
            <DialogContentText>Recipients</DialogContentText>
            <Query query={this.props.query}>
              {({ data }) => {
                return (
                  <RadioSwitch
                    column
                    name="recipients"
                    input={{
                      onChange: this.handleRadioChange,
                      value: recipients,
                    }}
                    options={
                      data && data.__type
                        ? data.__type.enumValues.map((option) =>
                            option.name
                              .toLowerCase()
                              .replace(/^\w/, (c) => c.toUpperCase())
                          )
                        : []
                    }
                  />
                )
              }}
            </Query>

            <DialogContentText>Grades</DialogContentText>
            <Flex>
              <GradeSelector name="fromGrade" title="From" value={fromGrade} />
              <GradeSelector name="toGrade" title="To" value={toGrade} />
            </Flex>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </FormControl>
    )
  }
}

export default Audience
