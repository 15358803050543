import deepmerge from 'deepmerge'

import types from '../actions/types'

const defaultState = {}

export default (state = defaultState, action) => {
  // eslint-disable-next-line
  const { type, payload, meta, error } = action

  let diff = {}

  switch (type) {
    default: {
      return state
    }
    case types.CACHE_BANNER: {
      diff = payload
      break
    }
    case types.CLEAR_BANNER: {
      return defaultState
    }
  }

  return deepmerge(state, diff)
}
