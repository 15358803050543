import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const styles = {
  root: {
    width: '100%',
    marginTop: 30,
  },
}

const LinearDeterminate = (props) => (
  <LinearProgress
    mode="determinate"
    value={props.now}
    classes={props.classes}
  />
)

LinearDeterminate.propTypes = {
  now: PropTypes.number.isRequired,
}

export default withStyles(styles, { name: 'LinearDeterminate' })(
  LinearDeterminate
)
