import * as React from 'react'

import Radio from '@material-ui/core/Radio'
import Text from '@material-ui/core/Typography'

import { Flex, Spacer } from '.'

const YesNoRadio = ({ input }) => (
  <Flex align="center">
    <Radio checked={input.value} onChange={() => input.onChange(true)} />
    <Text>Yes</Text>
    <Spacer left={'1em'} />
    <Radio checked={!input.value} onChange={() => input.onChange(false)} />
    <Text>No</Text>
  </Flex>
)

export default YesNoRadio
