import deepmerge from 'deepmerge'

import { STATUS } from '../../../components/StatusIndicator'
import types from '../actions/types'

const defaultState = {
  list: STATUS.INITIAL,
  create: STATUS.INITIAL,
  save: STATUS.INITIAL,
  swap: [],
}

export default (state = defaultState, action) => {
  // eslint-disable-next-line
  const { type, payload, meta, error } = action

  let diff = {},
    mergeOptions = null

  switch (type) {
    default: {
      return state
    }
    case types.CREATE_BANNER_START: {
      diff = {
        create: STATUS.LOADING,
      }
      break
    }
    case types.CREATE_BANNER_END: {
      diff = {
        create: error ? STATUS.ERROR : STATUS.READY,
      }
      break
    }
    case types.CREATE_BANNER_RESET: {
      diff = {
        create: STATUS.INITIAL,
      }
      break
    }

    case types.FETCH_BANNERS_START: {
      diff = {
        list: STATUS.LOADING,
      }
      break
    }
    case types.FETCH_BANNERS_END: {
      diff = {
        list: error ? STATUS.ERROR : STATUS.READY,
      }
      break
    }

    case types.SAVE_BANNER_START: {
      diff = {
        save: STATUS.LOADING,
      }
      break
    }
    case types.SAVE_BANNER_END: {
      diff = {
        save: error ? STATUS.ERROR : STATUS.READY,
      }
      break
    }
    case types.SAVE_BANNER_RESET: {
      diff = {
        save: STATUS.INITIAL,
      }
      break
    }

    case types.SWAP_BANNER_START: {
      const { banner1, banner2 } = meta
      if (banner2.id) {
        diff = {
          swap: [banner1.id, banner2.id],
        }
      } else {
        diff = {
          swap: [banner1.id],
        }
      }
      break
    }
    case types.SWAP_BANNER_END: {
      diff = {
        swap: [],
      }
      mergeOptions = {
        arrayMerge: (dest, source, options) => source,
      }
      break
    }

    case types.ARCHIVE_BANNER_START: {
      diff = {
        archive: STATUS.LOADING,
      }
      break
    }
    case types.ARCHIVE_BANNER_END: {
      diff = {
        archive: error ? STATUS.ERROR : STATUS.INITIAL,
      }
      break
    }
    case types.ARCHIVE_BANNER_RESET: {
      diff = {
        archive: STATUS.INITIAL,
      }
      break
    }
  }

  return deepmerge(state, diff, mergeOptions)
}
