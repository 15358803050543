import React from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'
import { Flex } from '.'

const COLORS = ['#0088FE', '#00C49F', '#db301a', '#FF8042']

const convertDegreeToRadian = (degree) => degree * (Math.PI / 180)

const PieChartComponent = ({ total, values, title, colors }) => {
  let [remainingLabel, remaining] = Object.entries(total)[0]

  const data = []
  let i = 0
  for (const label in values) {
    if (values[label]) {
      data.push({
        name: label,
        value: values[label],
        fill: colors[i % colors.length],
      })
      remaining -= values[label]
    }
    i++
  }
  if (remaining > 0) {
    data.push({
      name: remainingLabel,
      value: remaining,
      fill: colors[i % colors.length],
    })
  }

  return (
    <Flex column>
      <PieChart width={350} height={270}>
        <Pie
          data={data}
          cx={'50%'}
          cy={'50%'}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
      {title}
    </Flex>
  )
}

PieChartComponent.defaultProps = {
  colors: COLORS,
}

const renderCustomizedLabel = ({ cx, cy, midAngle, outerRadius, percent }) => {
  const labelLength = 23
  const radius = outerRadius + labelLength
  const x = cx + radius * Math.cos(-convertDegreeToRadian(midAngle))
  const y = cy + radius * Math.sin(-convertDegreeToRadian(midAngle))

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'}>
      {`${Math.round(percent * 1000) / 10}%`}
    </text>
  )
}

export default PieChartComponent
