// @flow

import React from 'react'
import styled from 'styled-components'
import { Route, Link, withRouter } from 'react-router-dom'

import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'

import MenuIcon from '@material-ui/icons/Menu'

import { compose } from 'recompose'
import { connect } from 'react-redux'
import pluralize from 'pluralize'
import { version } from '../../../package.json'
import { dev as debug } from '../../config'
import Text from '../../components/Text'

type User = {
  email: string,
}

type Props = {
  user: ?User, // If user is not null, someone is logged in.
  title?: string,
  breadcrumbs: {
    [string]: string,
  },
  openMenu?: () => void,
  wide: boolean,
}

const Bar = styled(MuiAppBar).attrs({
  position: 'static',
})``

const Title = styled(Text).attrs({
  variant: 'title',
  color: 'inherit',
  align: 'left',
})`
  flex: 1;
  padding-left: 1em;
`

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const toTitle = (str: string) =>
  pluralize(
    str.substring(0, 1).toUpperCase() +
      str
        .substring(1)
        .split('')
        .map((char) =>
          char === char.toUpperCase() && char !== char.toLowerCase()
            ? ' ' + char
            : char
        )
        .join('')
  )

const Breadcrumb = ({
  breadcrumbs,
  location,
  match: {
    url,
    params: { component },
  },
}) => {
  const title =
    breadcrumbs[component] ||
    (component.length === 36 ? 'Unknown' : toTitle(component))

  return (
    <div style={{ display: 'inline' }}>
      {' / '}
      {url === location.pathname ? (
        title
      ) : (
        <StyledLink to={url}>{title}</StyledLink>
      )}
      <Route
        path={`${url}/:component`}
        render={(routeData) => (
          <Breadcrumb {...routeData} breadcrumbs={breadcrumbs} />
        )}
      />
    </div>
  )
}

const AppBar = (props: Props) => (
  <Bar>
    <Toolbar>
      <IconButton aria-label="Menu" onClick={props.openMenu}>
        <MenuIcon style={{ color: 'white' }} />
      </IconButton>
      <Title>
        {debug ? (
          <StyledLink to="/">{`Ardent Labs LCMS (ALPHA) v${version}`}</StyledLink>
        ) : (
          <StyledLink to="/">{`Ardent Labs LCMS v${version}`}</StyledLink>
        )}

        <Route
          path="/:component"
          render={(routeData) => (
            <Breadcrumb {...routeData} breadcrumbs={props.breadcrumbs} />
          )}
        />
      </Title>
    </Toolbar>
  </Bar>
)

export default compose(
  withRouter, // Need to update component when route changes. Doesn't work without.
  connect(({ app: { breadcrumbs } }) => ({
    breadcrumbs,
  }))
)(AppBar)
