// @flow

import React from 'react'
import styled from 'styled-components'

import A from './A'
import Emoji from './Emoji'
import View from './View'

const StyledFooter = styled.footer`
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.2em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  border-top: 1px solid #aaa;
  opacity: 0.54;
`

const Footer = () => (
  <StyledFooter>
    <View>
      Ardent Academy © 2016-
      {new Date().getFullYear()}
    </View>
    <View>
      Made with <Emoji type="heart" /> by{' '}
      <A href="https://github.com/ArdentLabs">Ardent Labs</A>
    </View>
  </StyledFooter>
)

export default Footer
