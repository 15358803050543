// @flow

import * as React from 'react'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'

import { propPass } from '../../utils/props'

type Props = {
  // Selection controls
  label: React.Node,
  options: Array<{ value: string, label: React.Node, props: any }>,

  // Prop passing
  formControlProps: any,
  labelProps: any,
  selectProps: any,
  helperTextProps: any,

  // Injected by redux form
  input: any,
  meta: any,
}

const SelectField = ({
  label,
  options,
  formControlProps,
  labelProps,
  selectProps,
  helperTextProps,
  input,
  showPlaceholder,
  meta,
}: Props) => (
  <FormControl
    {...propPass(
      {
        error: Boolean(meta && meta.touched && meta.error),
      },
      formControlProps
    )}
  >
    <InputLabel
      {...propPass(
        {
          htmlFor: input.name,
          shrink: selectProps && selectProps.displayEmpty,
        },
        labelProps
      )}
    >
      {label}
    </InputLabel>
    <Select
      {...propPass(
        {
          id: input.name,
          ...input,
        },
        selectProps
      )}
    >
      {showPlaceholder ? (
        <MenuItem value="" disabled>
          {label}
        </MenuItem>
      ) : null}
      {options.map(({ value, label, props }) => (
        <MenuItem
          {...propPass({ key: value, value, children: label }, props)}
        />
      ))}
    </Select>
    <FormHelperText
      {...propPass(
        {
          children: meta && meta.touched ? meta.error : '',
        },
        helperTextProps
      )}
    />
  </FormControl>
)

export default SelectField
