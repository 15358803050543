// @flow

import { apiUrl } from '../config'

const url = apiUrl + '/graphql'

type QueryFunc = (
  gqlQuery: string, // The query string
  variables?: Object, // Query variables go in here
  fetchOptions?: Object // Additional fetch options; options specified here will override default options
) => Promise<*>

// Query our GraphQL API endpoint.
export const call: QueryFunc = async (gqlQuery, variables, fetchOptions) => {
  const { headers, body, ...otherOptions } = fetchOptions || {}
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify({
      query: gqlQuery,
      // We're ignoring operationName here since I don't know what it does yet.
      variables,
      ...body,
    }),
    ...otherOptions,
  }

  try {
    const result = await fetch(url, options)
    return result.json()
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}
